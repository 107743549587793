<!--
 * @Descripttion:
 * @Author: 张明尧
 * @Date: 2020-11-26 14:51:26
 * @LastEditTime: 2021-09-22 10:17:53
-->
<template>
  <div class="Jepaas-myTask background-FFFFFF">
    <el-backtop />
    <div>
      <div class="box_top">
        <Header />
      </div>
    </div>
    <div class="je-detail-content">
      <div class="font-size-30 color-3F3F3F font-weight-600">我的工单</div>
      <div>
        <el-row class="margin-top-40">
          <el-col :span="3">
            <!-- 快速查询的菜单 -->
            <div class="padding-left-20">
              <el-dropdown @command="taskOnSelect" trigger="click">
                <span
                  class="color-F84E4E font-size-24 border-bottom-2-solid-F84E4E font-weight-500 cursor-pointer-hover display-block padding-bottom-5"
                >
                  {{ taskSelectText.DICTIONARYITEM_ITEMNAME }}
                  <i
                    class="jeicon jeicon-sort-small-down font-size-20 margin-left-10"
                  />
                </span>
                <el-dropdown-menu slot="dropdown" style="width: 100px">
                  <el-dropdown-item
                    v-for="(select, _select) in taskSelect"
                    :key="_select"
                    :command="select"
                  >
                    <span>{{ select.DICTIONARYITEM_ITEMNAME }}</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </el-col>
          <el-col :span="7" class="elemnt-input-YGDD">
            <span class="font-size-16 color-3F3F3F vertical-align-middle">关键词：</span>
            <el-input
              v-model="GD_id"
              @change="searchInput"
              clearable
              class="border-none vertical-align-middle"
              placeholder="工单编号、问题标题"
            >
              <i
                slot="prefix"
                :class="GD_id && 'cursor-pointer-hover-F9514F' || ''"
                class="el-input__icon el-icon-search"
              />
            </el-input>
          </el-col>
          <el-col :span="5">
            <!-- 快速查询的菜单 -->
            <div class="elemnt-input-GDLX">
              <span class="font-size-16 color-3F3F3F vertical-align-middle">工单类型：</span>
              <el-select
                @change="taskTypeSelectFunc"
                v-model="taskTypeSelectText.code"
                placeholder="请选择"
              >
                <el-option
                  v-for="(select, _select) in taskTypeSelect"
                  :key="_select"
                  :label="select.name"
                  :value="select.code"
                />
              </el-select>
            </div>
          </el-col>
          <el-col :span="6" class="text-status-right">
            <el-button
              v-if="tableSelection.length > 0"
              @click="handleRemoveClick(tableSelection)"
              style="background: #3F3F3F;color: #fff;border-color: #3F3F3F; padding: 5px 10px"
              type="success"
              class="font-size-16"
            >
              <i class="jeicon jeicon-error-small font-size-12 vertical-align-middle" />
              <span class="vertical-align-middle margin-left-10">删除工单</span>
            </el-button>
          </el-col>
          <el-col
            :span="3"
            :offset="tableSelection && tableSelection.length == 0 && 6"
            class="text-status-right"
          >
            <el-button
              @click="addGD_btn"
              :style="'color: #fff; border-color: #FC6051;background: #FC6051;'"
              :type="'danger'"
              style="padding: 8px 15px"
            >
              <i class="jeicon jeicon-plus font-size-12 vertical-align-middle" />
              <span class="vertical-align-middle margin-left-10">创建工单</span>
            </el-button>
          </el-col>
          <!-- <el-col :span="3" class="text-status-right">
            <el-button
              :disabled="!userInfo.customerId"
              @click="getTaskViptime"
              :style="userInfo.customerId && 'background: #F19B2C;color: #fff;border-color: #F19B2C;'"
              type="warning"
              style="padding: 8px 15px"
            >
              <i class="jeicon jeicon-vip-v font-size-12 vertical-align-middle" />
              <span class="vertical-align-middle margin-left-10">VIP服务</span>
            </el-button>
          </el-col> -->
        </el-row>
      </div>
      <div class="scrollbarTabTask position-relative margin-top-20">
        <Loading :loadingData="{isShow: orderTableLoading}" />
        <el-table :data="tableData" @selection-change="handleSelectionChange" style="width: 100%;">
          <el-table-column :selectable="checkSelectable" fixed type="selection" width="55" />
          <el-table-column prop="KHGD_GDBH" label="工单编号" width="100" />
          <el-table-column label="工单状态" width="120">
            <template slot-scope="scope">
              <span
                :style="getColorsFilter(scope.row.KHGD_GZTYLE_CODE)"
              >{{ scope.row.KHGD_GZTYLE_NAME }}</span>
              <span v-if="scope.row.isOldData" style="margin: 0 0 0 10px">(旧)</span>
            </template>
          </el-table-column>
          <el-table-column label="问题标题" width="300">
            <template slot-scope="scope">
              <span
                @click="find_GD(scope.row)"
                style="color: #409EFF"
                class="cursor-pointer-hover textOmit"
              >{{ scope.row.KHGD_WTBT }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="KHGD_GDLX_NAME" label="工单类型" width="140" />
          <el-table-column prop="KHGD_YJKSSJ" label="预计开始时间" width="140" />
          <el-table-column prop="KHGD_SJWCRQ" label="实际完成时间" width="140" />
          <el-table-column prop="KHGD_YYGS" label="已用工时" align="center" width="100">
            <template slot-scope="scope">
              <span>{{ filterTime(scope.row.KHGD_YYGS) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="SY_CREATETIME" label="创建时间" width="140">
            <template slot-scope="scope">
              <span>{{ spliceTime(scope.row.SY_CREATETIME) }}</span>
            </template>
          </el-table-column>
          <el-table-column fixed="right" align="center" label="操作" width="120">
            <template slot-scope="scope">
              <el-button
                v-if="scope.row.KHGD_GZTYLE_CODE == 'WQD'"
                @click="handleEditClick(scope.row)"
                type="text"
                size="small"
              >编辑</el-button>
              <el-button
                v-if="['WQD'].includes(scope.row.KHGD_GZTYLE_CODE)"
                @click="handleRemoveClick(scope.row)"
                type="text"
                size="small"
              >删除</el-button>
              <el-button
                v-if="['DYS', 'YGB'].includes(scope.row.KHGD_GZTYLE_CODE)"
                @click="find_GD(scope.row)"
                type="text"
                size="small"
              >查看反馈</el-button>
               <el-button
                v-if="['YTJ'].includes(scope.row.KHGD_GZTYLE_CODE)"
                @click="subTaskFunc(scope.row, 'SH', '收回')"
                type="text"
                size="small"
              >收回</el-button>
              <el-button
                v-if="['DYS'].includes(scope.row.KHGD_GZTYLE_CODE)"
                @click="subTaskFunc(scope.row, 'GB', '关闭')"
                type="text"
                size="small"
              >关闭</el-button>
              <el-button
                v-if="['YBH'].includes(scope.row.KHGD_GZTYLE_CODE) && !scope.row.isOldData" 
                @click="subTaskFunc(scope.row, 'GB', '关闭')"
                type="text"
                size="small"
              >关闭</el-button>
              <el-button
                v-if="['YGB'].includes(scope.row.KHGD_GZTYLE_CODE) && !scope.row.isOldData"
                @click="copyDataCreateNewTask(scope.row)"
                type="text"
                size="small"
              >复制创建工单</el-button>
              <div v-if="!['DYS', 'YTJ', 'WQD', 'YGB', 'YBH'].includes(scope.row.KHGD_GZTYLE_CODE)">/</div>
            </template>
          </el-table-column>
        </el-table>
        <div v-show="totaCount > page_obj.limit" class="table-pagination" style="margin-top: 30px">
          <el-pagination
            :page-size="page_obj.limit"
            :total="totaCount"
            @current-change="blackPageHandleChange"
            background
            layout="prev, pager, next, jumper"
          />
        </div>
      </div>
      <div>
        <el-dialog
          :destroy-on-close="true"
          :close-on-click-modal="false"
          :before-close="close_drawer"
          :visible.sync="dialog"
          :title="find_GD_status && '查看工单' || edit_GD_status && '编辑工单' || '创建工单'"
          width="100%"
          custom-class="useExplainTask_Task myTask-drawer"
        >
          <div
            style=" height: 90%; overflow-y: auto; box-sizing: border-box;"
            class="padding-bottom-30"
          >
            <Loading :loadingData="{isShow: edit_GD_Loading}" />
            <!-- <div class="myTask-title">
              <el-steps
                :active="active_taskSteps"
                :space="100"
                :align-center="true"
                process-status="success"
                style="display: flex;justify-content: center;align-items: center;height: 100%;"
                finish-status="success"
              >
                <el-step
                  v-for="(ste, _ste) in taskSelectGD"
                  :key="_ste"
                  :title="ste.DICTIONARYITEM_ITEMNAME"
                >
                  <template slot="icon">
                    <i class="jeicon jeicon-elephant" />
                  </template>
                </el-step>
              </el-steps>
            </div> -->
          
            <div class="taskTop" v-if="isShowTaskInfo">
              <div class="item">
                <span class="iconWrap">
                  <i class="jeicon jeicon-alarm"></i>
                </span>
                项目服务时长：{{taskInfo.GDFA_KSRQ}} ～ {{taskInfo.GDFA_JSRQ}}
              </div>
              <div class="item">
                <span class="iconWrap">
                  <i class="jeicon jeicon-ddht-pc"></i>
                </span>
                总工单：<span class="number">{{taskInfo.GDFA_PFGDS}}</span>
              </div>
              <div class="item">已用工单 ： <span class="number">{{taskInfo.GDFA_YYGDS}}</span></div>
              <div class="item">剩余工单：<span class="number">{{taskInfo.GDFA_SYGDS}}</span></div>
            </div>

            <div class="taskTop" v-if="!isShowTaskInfo">
              <div class="item">
                <span class="iconWrap">
                  <i class="jeicon jeicon-alarm"></i>
                </span>
                项目服务时长：当前无项目
              </div>
              <div class="item">
                <span class="iconWrap">
                  <i class="jeicon jeicon-ddht-pc"></i>
                </span>
                总工单：<span class="number">0</span>
              </div>
              <div class="item">已用工单 ： <span class="number">0</span></div>
              <div class="item">剩余工单：<span class="number">0</span></div>
            </div>
            
            <div style="width: 60%;margin: 20px auto;" class="margin-top-10">
              <el-row class="displayHeightCenter">
                <el-col
                  :span="3"
                  style="height: 40px;line-height: 40px"
                  class="text-status-right font-size-16"
                >提出人:</el-col>
                <el-col :span="9" class="padding-left-20 displayHeightCenter">
                  <el-input
                    :readonly="find_GD_status"
                    v-model="zd_codes.KHGD_TCR"
                    placeholder="请输入提出人"
                  />
                </el-col>
                <el-col
                  :span="3"
                  style="height: 40px;line-height: 40px"
                  class="text-status-right font-size-16"
                >
                  <span class="mustFill">项目:</span>
                </el-col>
                <el-col :span="9" class="padding-left-20 displayHeightCenter">
                  <!-- xm_select -->
                  <!-- <el-input
                    v-model="zd_codes.KHGD_GDBH"
                    readonly
                  />-->
                  <el-select
                    :disabled="find_GD_status"
                    v-model="xm_select.value"
                    placeholder="请选择项目"
                    @change="changeXM"
                  >
                    <el-option
                      v-for="(item, index) in xm_select.valueArr"
                      :key="index"
                      :label="item.KHGD_XM"
                      :value="JSON.stringify(item)"
                    />
                  </el-select>
                </el-col>
              </el-row>
              <el-row class="margin-top-20 displayHeightCenter">
                <el-col
                  :span="3"
                  style="height: 40px;line-height: 40px"
                  class="text-status-right font-size-16"
                >
                  <span class="mustFill">平台产品:</span>
                </el-col>
                <el-col :span="9" class="padding-left-20 displayHeightCenter">
                  <el-select
                    :disabled="find_GD_status"
                    v-model="zd_codes.VIP_PRODUCT_TYOE.value"
                    @change="filterDicTree()"
                    placeholder="请选择平台"
                  >
                    <el-option
                      v-for="(item, index) in zd_codes.VIP_PRODUCT_TYOE.codes"
                      :key="index"
                      :label="item.DICTIONARYITEM_ITEMNAME"
                      :value="JSON.stringify(item)"
                    />
                  </el-select>
                </el-col>
                <el-col
                  :span="3"
                  style="height: 40px;line-height: 40px"
                  class="text-status-right font-size-16"
                >
                  <span class="mustFill">产品版本:</span>
                </el-col>
                <el-col :span="9" class="padding-left-20 displayHeightCenter">
                  <el-select
                    :disabled="find_GD_status"
                    v-model="zd_codes.VIP_PRODUCT_TYOE_TREE.value"
                    placeholder="请选择平台产品版本"
                  >
                    <el-option
                      v-for="(item, index) in zd_codes.VIP_PRODUCT_TYOE_TREE.codes"
                      :key="index"
                      :label="item.DICTIONARYITEM_ITEMNAME"
                      :value="JSON.stringify(item)"
                    />
                  </el-select>
                </el-col>
              </el-row>
              <el-row type="flex" class="margin-top-20">
                <el-col
                  :span="3"
                  style="height: 40px;line-height: 40px"
                  class="text-status-right font-size-16"
                >
                  <span class="mustFill">操作系统:</span>
                </el-col>
                <el-col :span="9" class="padding-left-20 displayHeightCenter">
                  <el-select
                    :disabled="find_GD_status"
                    v-model="zd_codes.JE_PRO_OS.value"
                    placeholder="请选择操作系统"
                  >
                    <el-option
                      v-for="(item, index) in zd_codes.JE_PRO_OS.codes"
                      :key="index"
                      :label="item.DICTIONARYITEM_ITEMNAME"
                      :value="JSON.stringify(item)"
                    />
                  </el-select>
                </el-col>
                <el-col
                  :span="3"
                  style="height: 40px;line-height: 40px"
                  class="text-status-right font-size-16"
                >
                  <span class="mustFill">数据库:</span>
                </el-col>
                <el-col :span="9" class="padding-left-20 displayHeightCenter">
                  <el-select
                    :disabled="find_GD_status"
                    v-model="zd_codes.VIP_DB_TYPE.value"
                    placeholder="请选择数据库"
                  >
                    <el-option
                      v-for="(item, index) in zd_codes.VIP_DB_TYPE.codes"
                      :key="index"
                      :label="item.DICTIONARYITEM_ITEMNAME"
                      :value="JSON.stringify(item)"
                    />
                  </el-select>
                </el-col>
              </el-row>
              <el-row type="flex" class="margin-top-20">
                <el-col
                  :span="3"
                  style="height: 40px;line-height: 40px"
                  class="text-status-right font-size-16"
                >
                  <span class="mustFill">问题类型:</span>
                </el-col>
                <el-col :span="9" class="padding-left-20 displayHeightCenter">
                  <el-select
                    :disabled="find_GD_status"
                    v-model="zd_codes.JE_PM_QZLX.value"
                    placeholder="请选择类型"
                  >
                    <el-option
                      v-for="(item, index) in zd_codes.JE_PM_QZLX.codes"
                      :key="index"
                      :label="item.DICTIONARYITEM_ITEMNAME"
                      :value="JSON.stringify(item)"
                    />
                  </el-select>
                </el-col>
                <el-col
                  :span="3"
                  style="height: 40px;line-height: 40px"
                  class="text-status-right font-size-16"
                >
                  <span class="mustFill">浏览器:</span>
                </el-col>
                <el-col :span="9" class="padding-left-20 displayHeightCenter">
                  <el-select
                    :disabled="find_GD_status"
                    v-model="zd_codes.JE_PRO_BORWSER.value"
                    placeholder="请选择浏览器"
                  >
                    <el-option
                      v-for="(item, index) in zd_codes.JE_PRO_BORWSER.codes"
                      :key="index"
                      :label="item.DICTIONARYITEM_ITEMNAME"
                      :value="JSON.stringify(item)"
                    />
                  </el-select>
                </el-col>
              </el-row>
              <el-row type="flex" class="margin-top-20" justify="center">
                <el-col
                  :span="3"
                  style="height: 40px;line-height: 40px"
                  class="text-status-right font-size-16"
                >
                  <span class="mustFill">项目计划:</span>
                </el-col>
                <el-col :span="21" class="padding-left-20 displayHeightCenter">
                  <el-select
                    :disabled="find_GD_status"
                    v-model="xmjh_select.value"
                    placeholder="请选择项目计划"
                  >
                    <el-option
                      v-for="(item, index) in xmjh_select.valueArr"
                      :key="index"
                      :label="item.XMJH_MC"
                      :value="JSON.stringify(item)"
                    />
                  </el-select>
                </el-col>
              </el-row>
              <el-row type="flex" class="margin-top-20" justify="center">
                <el-col
                  :span="3"
                  style="height: 40px;line-height: 40px"
                  class="text-status-right font-size-16"
                >
                  <span class="mustFill">远程调试:</span>
                </el-col>
                <el-col :span="21" class="padding-left-20 displayHeightCenter">
                  <el-input
                    :readonly="find_GD_status"
                    v-model="zd_codes.KHGD_YCDSXX"
                    placeholder="调试项目链接地址，账号和密码，如是内网，需提供服务器地址账号密码或向日葵，QQ远程"
                  />
                </el-col>
              </el-row>
              <el-row type="flex" class="margin-top-20">
                <el-col
                  :span="3"
                  style="height: 40px;line-height: 40px"
                  class="text-status-right font-size-16"
                >
                  <span class="mustFill">问题标题:</span>
                </el-col>
                <el-col :span="21" class="padding-left-20 displayHeightCenter">
                  <el-input
                    :readonly="find_GD_status"
                    v-model="zd_codes.KHGD_WTBT"
                    placeholder="请输入问题标题"
                  />
                </el-col>
              </el-row>
              <el-row type="flex" class="margin-top-20">
                <el-col
                  :span="3"
                  style="height: 40px;line-height: 40px"
                  class="text-status-right font-size-16"
                >
                  <span class="mustFill">问题描述:</span>
                  <div v-if="zd_codes.KHGD_WTMS">
                    <el-tooltip class="item" effect="dark" content="窗口查看" placement="top">
                      <i
                        @click="showWindow(zd_codes.KHGD_WTMS)"
                        class="jeicon jeicon-copy cursor-pointer-hover"
                      />
                    </el-tooltip>
                  </div>
                </el-col>
                <el-col
                  :span="21"
                  class="padding-left-20 displayHeightCenter editor-task position-relative"
                >
                  <Loading :loadingData="{isShow: quillUpdateImg}" />
                  <el-upload
                    :action="serverUrl"
                    :headers="header"
                    :data="uploadData"
                    :show-file-list="false"
                    :multiple="false"
                    :on-success="uploadSuccess"
                    :on-error="uploadError"
                    :before-upload="beforeAvatarUpload"
                    class="avatar-uploader"
                    name="file"
                    list-type="picture"
                  />

                  <quill-editor
                    ref="myQuillEditor"
                    v-model="zd_codes.KHGD_WTMS"
                    :options="editorOption"
                    @focus="onEditorFocus($event)"
                    style="width: 100%;"
                  />
                </el-col>
              </el-row>
              <el-row v-if="zd_codes.KHGD_GZTYLE_CODE == 'YBH'" type="flex" class="margin-top-20">
                <el-col
                  :span="3"
                  style="height: 40px;line-height: 40px"
                  class="text-status-right font-size-16"
                >
                  <span>驳回原因:</span>
                </el-col>
                <el-col
                  :span="21"
                  class="padding-left-20 displayHeightCenter editor-task position-relative"
                >{{ zd_codes.KHGD_BHXX }}</el-col>
              </el-row>
              <el-row type="flex" class="margin-top-20">
                <el-col
                  :span="3"
                  style="height: 40px;line-height: 40px"
                  class="text-status-right font-size-16"
                >问题附件:</el-col>
                <el-col :span="21" class="padding-left-20">
                  <el-upload
                    :action="serverUrl"
                    :headers="header"
                    :data="uploadData"
                    :file-list="fileList"
                    :show-file-list="false"
                    :limit="5"
                    :multiple="true"
                    :on-success="uploadFJSuccess"
                    :before-upload="beforeAvatarUpload_fj"
                    :on-exceed="upload_fjExceed"
                    style="display: none;"
                    class="avatar-uploader-fj"
                    name="file"
                    list-type="picture"
                  />
                  <!-- <div>
                    <el-button
                      v-if="!find_GD_status || zd_codes.KHGD_WTFJ.length <= 5"
                      @click="uploadFJ"
                      :loading="quillUpdateImg_fj"
                      type="primary"
                    >
                      <i class="jeicon jeicon-folder-add" />
                      {{ quillUpdateImg_fj? '上传中 ...' : '附件上传' }}
                    </el-button>
                  </div>-->
                  <div class="FJSC_class margin-top-10 position-relative">
                    <Loading :loadingData="{isShow: quillUpdateImg_fj}" />
                    <div
                      v-for="(file_fj, _file) in zd_codes.KHGD_WTFJ"
                      :key="_file"
                      style="width: 20%"
                      class="FJSC_every"
                    >
                      <div @click="downloadPTMS(file_fj)" class="cursor-pointer-hover">
                        <i
                          :style="`color: ${getFileSuffixIcon(file_fj).color}`"
                          :class="getFileSuffixIcon(file_fj).icon"
                          class="jeicon font-size-40"
                        />
                      </div>
                      <div
                        class="font-size-14 margin-top-10 textOmit text-status-center"
                      >{{ file_fj.name || '暂无' }}</div>
                    </div>
                    <div v-if="!find_GD_status && zd_codes.KHGD_WTFJ.length < 5">
                      <div @click="uploadFJ" class="upload_fuJi">
                        <i class="el-icon-plus avatar-uploader-icon" />
                      </div>
                    </div>
                  </div>
                </el-col>
              </el-row>
              <el-row v-if="zd_codes.KHGD_PTFK" type="flex" class="margin-top-35">
                <el-col
                  :span="3"
                  style="height: 40px;line-height: 40px"
                  class="text-status-right font-size-16"
                >
                  <span class="mustFill">平台反馈:</span>
                  <div v-if="zd_codes.KHGD_PTFK">
                    <el-tooltip class="item" effect="dark" content="窗口查看" placement="top">
                      <i
                        @click="showWindow(zd_codes.KHGD_PTFK)"
                        class="jeicon jeicon-copy cursor-pointer-hover"
                      />
                    </el-tooltip>
                  </div>
                </el-col>
                <el-col
                  :span="21"
                  class="padding-left-20 displayHeightCenter editor-task position-relative"
                >
                  <div style="width: 100%" class="ql-container ql-snow">
                    <div class="ql-editor">
                      <div v-html="zd_codes.KHGD_PTFK" class="details_box" />
                    </div>
                  </div>
                </el-col>
              </el-row>
              <el-row v-if="zd_codes.KHGD_PTFKFJ.length > 0" type="flex" class="margin-top-35">
                <el-col
                  :span="3"
                  style="height: 40px;line-height: 40px"
                  class="text-status-right font-size-16"
                >
                  <span class="mustFill">平台反馈附件:</span>
                </el-col>
                <el-col
                  :span="21"
                  class="padding-left-20 displayHeightCenter editor-task position-relative"
                >
                  <div style="width: 100%" class="FJSC_class margin-top-10 position-relative">
                    <div
                      v-for="(file_fj, _file) in zd_codes.KHGD_PTFKFJ"
                      :key="_file"
                      style="width: 20%"
                      class="FJSC_every"
                    >
                      <div @click="downloadPTMS(file_fj)" class="cursor-pointer-hover">
                        <i
                          :style="`color: ${getFileSuffixIcon(file_fj).color}`"
                          :class="getFileSuffixIcon(file_fj).icon"
                          class="jeicon font-size-40"
                        />
                      </div>
                      <div
                        class="font-size-14 margin-top-10 textOmit text-status-center"
                      >{{ file_fj.name || '暂无' }}</div>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
            <div
              v-if="!find_GD_status"
              style="bottom: 20px;width: 100%"
              class="text-status-center position-absolute"
            >
              <el-button
                @click="cancelForm()"
                style="background: #F19B2C; color: #fff; border-color: #F19B2C;"
              >保 存</el-button>
              <el-button
                v-show="!edit_GD_status || ['WQD', 'YBH'].includes(zd_codes.KHGD_GZTYLE_CODE)"
                @click="subGD()"
                :loading="sub_loading || quillUpdateImg_fj"
                class="margin-left-40"
                style="background: #EF4D4D; color: #fff; border-color: #EF4D4D;"
                type="primary"
              >{{ sub_loading || quillUpdateImg_fj? '等待中 ...' : '提交工单' }}</el-button>
            </div>
          </div>
        </el-dialog>
        <!-- <el-drawer
          ref="drawer"
          :modal="false"
          :wrapperClosable="false"
          :before-close="handleClose"
          :visible.sync="dialog"
          :title="find_GD_status && '查看工单' || edit_GD_status && '编辑工单' || '创建工单'"
          size="95%"
          width="30%"
          direction="rtl"
          custom-class="myTask-drawer"
        >

        </el-drawer>-->
      </div>
      <div>
        <!-- 窗口查看问题描述 -->
        <el-dialog
          :visible.sync="showWindowDialog"
          width="90%"
          custom-class="useExplain_Task_showWindow"
        >
          <div slot="title" class="useTitle">内容查看</div>
          <div
            style="line-height: 2"
            class="color-3F3F3F font-size-18 padding-bottom-30 padding-LR-20 padding-top-10"
          >
            <div v-if="showWindowData" v-html="showWindowData" />
          </div>
        </el-dialog>
        <!-- 不是VIP用户 -->
        <el-dialog :visible.sync="VIP_CODE_show" width="40%" custom-class="useExplain_Task">
          <div slot="title" class="useTitle">VIP服务提示</div>
          <div
            style="line-height: 2"
            class="color-3F3F3F font-size-18 padding-bottom-30 padding-LR-20 padding-top-10"
          >
            <div class="text-status-center font-size-20 font-weight-600">
              您当前没有绑定VIP客户码，
              <span
                @click="VIP_CODE_showGo"
                style="color: #F9514F"
                class="cursor-pointer-hover-F9514F"
              >请先前往绑定客户码!</span>
            </div>
          </div>
        </el-dialog>
        <!-- 没有项目 -->
        <el-dialog :visible.sync="task_verify_dialog.isShow" width="40%" custom-class="useExplain_Task">
          <div slot="title" class="useTitle">提示</div>
          <div
            style="line-height: 2"
            class="color-3F3F3F font-size-18 padding-bottom-30 padding-LR-20 padding-top-10"
          >
            <div class="text-status-center font-size-20 font-weight-600">
              {{task_verify_dialog.txt}}
            </div>
          </div>
        </el-dialog>
        <!-- 使用说明 -->
        <el-dialog
          :visible.sync="vipCheck_obj.vipCheckDialog"
          width="40%"
          custom-class="useExplain_Task"
        >
          <div slot="title" class="useTitle">VIP服务时间</div>
          <div
            style="line-height: 2"
            class="color-3F3F3F font-size-18 padding-bottom-30 padding-LR-20 padding-top-10"
          >
            <div
              class="text-status-center font-size-20 font-weight-600"
            >{{ vipCheck_obj.vipLength_0 ? '您当前没有VIP服务记录，请联系客服购买VIP服务' : '尊贵的VIP客户您的有效服务时长为' }}</div>
            <div v-if="vipCheck_obj.time.start " class="text-status-center font-weight-600">
              <span style="color: #EF4D4D">{{ vipCheck_obj.time.start }}</span> 至
              <span style="color: #EF4D4D">{{ vipCheck_obj.time.end }}</span>
            </div>
            <div v-if="vipCheck_obj.time.start " class="vipCount_main vipCount_main_bottom">
              <div class="every_vip_time">
                工单总量:
                <div style="background: #EF4D4D" class="vipCount">{{ vipCheck_obj.VIPTIME_GDSL }}</div>
              </div>
              <div class="every_vip_time">
                已用工单数:
                <div style="background: #EF4D4D" class="vipCount">{{ vipCheck_obj.VIPTIME_YYGDSL }}</div>
              </div>
              <div class="every_vip_time">
                剩余工单数:
                <div
                  style="background: #EF4D4D"
                  class="vipCount"
                >{{ vipCheck_obj.VIPTIME_GDSL - vipCheck_obj.VIPTIME_YYGDSL }}</div>
              </div>
            </div>
            <div :style="!vipCheck_obj.vipLength_0 && 'height: 200px;'" style="overflow-y: auto;">
              <div
                v-for="(future, _future) in vipCheck_obj.vip_arr.future"
                :key="`${_future}_future`"
                class="margin-top-15"
              >
                <div class="text-status-center font-weight-600">
                  <span style="color: #6A9CFF">{{ future.VIPTIME_KSSJ }}</span> 至
                  <span style="color: #6A9CFF">{{ future.VIPTIME_JSSJ }}</span>
                </div>
                <div class="vipCount_main">
                  <div class="every_vip_time">
                    工单总量:
                    <div style="background: #6A9CFF" class="vipCount">{{ future.VIPTIME_GDSL }}</div>
                  </div>
                  <div class="every_vip_time">
                    已用工单数:
                    <div style="background: #6A9CFF" class="vipCount">{{ future.VIPTIME_YYGDSL }}</div>
                  </div>
                  <div class="every_vip_time">
                    剩余工单数:
                    <div
                      style="background: #6A9CFF"
                      class="vipCount"
                    >{{ future.VIPTIME_GDSL - future.VIPTIME_YYGDSL }}</div>
                  </div>
                </div>
              </div>
              <div
                v-for="(newDate, _newDate) in vipCheck_obj.vip_arr.newArr"
                :key="`${_newDate}_newArr`"
                class="margin-top-15"
              >
                <div class="text-status-center font-weight-600">
                  <span style="color: #EF4D4D">{{ newDate.VIPTIME_KSSJ }}</span> 至
                  <span style="color: #EF4D4D">{{ newDate.VIPTIME_JSSJ }}</span>
                </div>
                <div class="vipCount_main">
                  <div class="every_vip_time">
                    工单总量:
                    <div style="background: #EF4D4D" class="vipCount">{{ newDate.VIPTIME_GDSL }}</div>
                  </div>
                  <div class="every_vip_time">
                    已用工单数:
                    <div style="background: #EF4D4D" class="vipCount">{{ newDate.VIPTIME_YYGDSL }}</div>
                  </div>
                  <div class="every_vip_time">
                    剩余工单数:
                    <div
                      style="background: #EF4D4D"
                      class="vipCount"
                    >{{ newDate.VIPTIME_GDSL - newDate.VIPTIME_YYGDSL }}</div>
                  </div>
                </div>
              </div>
              <div
                v-for="(newDate, _newDate) in vipCheck_obj.vip_arr.oldArr"
                :key="`${_newDate}_oldArr`"
                class="margin-top-15"
              >
                <div class="text-status-center font-weight-600">
                  <span style="color: #D8D8D8">{{ newDate.VIPTIME_KSSJ }}</span> 至
                  <span style="color: #D8D8D8">{{ newDate.VIPTIME_JSSJ }}</span>
                </div>
                <div class="vipCount_main">
                  <div class="every_vip_time">
                    工单总量:
                    <div style="background: #D8D8D8" class="vipCount">{{ newDate.VIPTIME_GDSL }}</div>
                  </div>
                  <div class="every_vip_time">
                    已用工单数:
                    <div style="background: #D8D8D8" class="vipCount">{{ newDate.VIPTIME_YYGDSL }}</div>
                  </div>
                  <div class="every_vip_time">
                    剩余工单数:
                    <div
                      style="background: #D8D8D8"
                      class="vipCount"
                    >{{ newDate.VIPTIME_GDSL - newDate.VIPTIME_YYGDSL }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header'
import { quillEditor, Quill } from 'vue-quill-editor'
import { ImageExtend } from 'quill-image-paste-module'
import { getStorage, fileSuffixIcon, param } from '@/util/util'
import { getToken } from '@/util/auth'
import {
  getDictionary,
  getDoSave,
  getLoadWork,
  getDoRemove,
  getcheckVipService,
  getSponsorProcess,
  getDoUpdate,
  getViptime,
  getTaskProject,
  getPlan
} from '@/pages/index/actions/actions'
import { colorsFilter } from './util/index'

Quill.register('modules/ImageExtend', ImageExtend)
export default {
  name: 'MyTask',
  components: {
    Header,
    quillEditor
  },
  data() {
    return {
      uploadData: {},
      showWindowDialog: false, // 窗口查看
      showWindowData: '', // 窗口查看内容
      VIP_CODE_show: false, // VIP的客户码
      task_verify_dialog: {
        isShow: false,
        txt: '',
      },
      tableSelection: [], // 表格的选中数据
      change_GD_data: {}, // 当前点击的列表数据
      find_GD_status: false, // 当前是否是在查看
      edit_GD_status: false, // 当前是否是在编辑
      edit_GD_Loading: false, // 编辑的加载中
      quillUpdateImg: false, // 图片区域加载
      quillUpdateImg_fj: false, // 附件上传区域加载
      fileList: [], // 附件上传的文件数量
      serverUrl: '/jepaas/document/file', // 这里写你要上传的图片服务器地址
      header: {
        jepaasAuthorization: getToken(),
        'Platform-Agent': 'AppleWebKit/537.36 (KHTML, like Gecko)'
      }, // 有的图片服务器要求请求头需要有token
      orderTableLoading: false, // 区域加载
      vipCheck_obj: {
        vipCheckDialog: false, // vip服务信息查看
        vipLength_0: false,
        vip_arr: {
          oldArr: [],
          newArr: [],
          future: []
        }, // 当前的VIP记录
        time: {} // 到期时间
      },
      editorOption: {
        placeholder: '请输入文章内容',
        modules: {
          clipboard: {
            metchers: [['img', this.HandleCustomMatcher]]
          },
          ImageExtend: {
            loading: false,
            name: 'files',
            headers: (xhr) => {
              xhr.setRequestHeader('jepaasAuthorization', getToken())
            },
            action: '/jepaas/document/file',
            response: (res) =>
              `/jepaas/document/preview?fileKey=${res.obj[0].fileKey}`
          },
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
              ['blockquote', 'code-block'], // 引用  代码块
              [{ header: 1 }, { header: 2 }], // 1、2 级标题
              [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
              [{ script: 'sub' }, { script: 'super' }], // 上标/下标
              [{ indent: '-1' }, { indent: '+1' }], // 缩进
              // [{'direction': 'rtl'}],                         // 文本方向
              [{ size: ['small', false, 'large', 'huge'] }], // 字体大小
              [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
              [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
              [{ font: [] }], // 字体种类
              [{ align: [] }], // 对齐方式
              ['clean'], // 清除文本格式
              ['link', 'image', 'video'] // 链接、图片、视频
            ],
            handlers: {
              image(value) {
                if (value) {
                  // 触发input框选择图片文件
                  document.querySelector('.avatar-uploader input').click()
                } else {
                  this.quill.format('image', false)
                }
              }
            }
          }
        }
      },
      page_obj: {
        page: 1,
        start: 0,
        limit: 10
      }, // 分页 数据
      dialog: false,
      sub_loading: false,
      formLabelWidth: '80px',
      blackTotalCount: 20,
      xm_select: {
        value: '',
        valueArr: [],
        name: '项目'
      }, // 提交工单的项目选择
      xmjh_select: {
        value: '',
        valueArr: [],
        name: '项目计划'
      }, // 提交工单的项目计划选择
      zd_codes: {
        KHGD_GDBH: '<系统自动生成>',
        VIP_PRODUCT_TYOE: {
          value: '', // 当前下拉菜单选中的值
          codes: [], // 当前包含的所有下拉菜单
          type: 'select',
          name: '平台产品'
        }, // 平台产品字典
        JE_PRO_OS: {
          value: '', // 当前下拉菜单选中的值
          codes: [], // 当前包含的所有下拉菜单
          type: 'select',
          name: '操作系统'
        }, // 操作系统字典
        VIP_DB_TYPE: {
          value: '', // 当前下拉菜单选中的值
          codes: [], // 当前包含的所有下拉菜单
          type: 'select',
          name: '数据库'
        }, // 数据库字典
        JE_PRO_BORWSER: {
          value: '', // 当前下拉菜单选中的值
          codes: [], // 当前包含的所有下拉菜单
          name: '浏览器'
        }, // 浏览器字典
        JE_PM_QZLX: {
          value: '', // 当前下拉菜单选中的值
          codes: [], // 当前包含的所有下拉菜单
          name: '问题类型'
        }, // 问题类型字典
        VIP_PRODUCT_TYOE_TREE: {
          value: '', // 当前下拉菜单选中的值
          codes: [], // 当前包含的所有下拉菜单
          name: '产品版本'
        },
        KHGD_CPBB: '', // 产品版本
        KHGD_TCR: '', // 提出人
        KHGD_YCDSXX: '', // 远程调试信息
        KHGD_WTBT: '', // 问题标题
        KHGD_WTMS: '', // 问题描述
        KHGD_PTFK: '', // 平台反馈
        KHGD_PTFKFJ: [], // 平台反馈附件
        KHGD_WTFJ: [] // 问题附件
      },
      taskSelectText: {
        DICTIONARYITEM_ITEMCODE: 'all',
        DICTIONARYITEM_ITEMNAME: '全部'
      },
      taskSelect: [
        {
          DICTIONARYITEM_ITEMCODE: 'all',
          DICTIONARYITEM_ITEMNAME: '全部'
        }
      ],
      taskTypeSelectText: {
        code: 'all',
        name: '全部'
      },
      taskTypeSelect: [
        {
          code: 'all',
          name: '全部'
        },
        {
          code: 'PTQX',
          name: '平台缺陷'
        },
        {
          code: 'YBGD',
          name: '一般工单'
        }
      ],
      isShowTaskInfo: false,
      taskInfo: {},
      GD_id: '', // 工单编号查询
      tableData: [], // 工单列表数据
      totaCount: 0, // 总条数
      table_jq: {}, // 当前的列表的查询条件
      userInfo: {}, // 当前的用户信息
      keys_map: {
        VIP_PRODUCT_TYOE: {
          code: 'KHGD_PTCP_CODE',
          name: 'KHGD_PTCP_NAME'
        },
        JE_PRO_OS: {
          code: 'KHGD_CZXT_CODE',
          name: 'KHGD_CZXT_NAME'
        },
        VIP_DB_TYPE: {
          code: 'KHGD_SJK_CODE',
          name: 'KHGD_SJK_NAME'
        },
        JE_PRO_BORWSER: {
          code: 'KHGD_LLQ_CODE',
          name: 'KHGD_LLQ_NAME'
        },
        JE_PM_QZLX: {
          code: 'KHGD_WTLX_CODE',
          name: 'KHGD_WTLX_NAME'
        },
        VIP_PRODUCT_TYOE_TREE: {
          code: 'KHGD_CPBB',
          name: 'KHGD_CPBB'
        }
      } // 对应上传接口的数据对照
    }
  },
  computed: {
    // 工单中的当前进行状态
    taskSelectGD() {
      return this.taskSelect.filter(
        (res) => res.DICTIONARYITEM_ITEMNAME != '全部'
      )
    },
    active_taskSteps() {
      if (this.change_GD_data.KHGD_GZTYLE_CODE) {
        return this.taskSelectGD.findIndex(
          (res) =>
            res.DICTIONARYITEM_ITEMCODE == this.change_GD_data.KHGD_GZTYLE_CODE
        )
      }
      return 0
    }
  },
  watch: {
    'zd_codes.KHGD_WTMS': function (val, oldval) {
      if (val.includes('data:image/png;base64')) {
        const { quill } = this.$refs.myQuillEditor
        const objArr = Array.from(quill.container.getElementsByTagName('img'))
        objArr.forEach((v, index) => {
          if (v.outerHTML.includes('data:image/png;base64')) {
            v.parentNode.removeChild(v)
          }
        })
      }
    }
  },
  created() {
    this.userInfo = JSON.parse(getStorage('local', 'userInfo'))
    this.get_select()
    // this.load_GD() // 加载列表数据
  },
  methods: {
    uploadToServer(file, callback) {
      const xhr = new XMLHttpRequest()
      const formData = new FormData()
      formData.append('content', file)
      xhr.open('post', '服务器路径')
      xhr.withCredentials = true
      xhr.responseType = 'json'
      xhr.send(formData)
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          callback(xhr.response)
        }
      }
    },
    // 点击查看窗口显示内容
    showWindow(val) {
      this.showWindowData = ''
      this.showWindowDialog = true
      this.showWindowData = val
    },
    // 提交工单
    subGD() {
      if (!this.isSubData()) return // 判断必填项是否填写
      const params = this.paramsFilterSub()
      // 先校验用户的VIP信息
      this.vipCheckVipService().then((vip) => {
        // 如果Vip校验不通过
        if (!vip.success) {
          return this.errorMessage(vip.message)
        }
        // 弹框确认是否提交工单
        this.$confirm('您满足工单提交条件, 是否继续提交?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'success'
        })
          .then(() => {
            this.sub_loading = true // 提交按钮加载中
            // 提交工单的数据接口
            ;(!params.JEPAAS_VIP_KHGD_ID &&
              this.saveTaskPort(params).then((save) => {
                if (!save.success) {
                  this.close_drawer()
                  return
                }
                params.action = 'TJ'
                save.obj.JEPAAS_VIP_KHGD_ID &&
                  this.subTaskPort(save.obj.JEPAAS_VIP_KHGD_ID, params).then((sub) => {
                    this.sub_loading = false // 提交按钮加载中
                    if (!sub.success) return
                    this.close_drawer() // 关闭填写表单
                    this.$message.success('工单提交成功')
                    this.load_GD() // 重新获取最新的列表数据
                  })
              })) ||
              this.updateTaskPort(params).then((update) => {
                if (!update.success) {
                  this.close_drawer()
                  return
                }
                params.action = 'TJ'
                this.subTaskPort(params.JEPAAS_VIP_KHGD_ID, params).then((sub) => {
                  this.sub_loading = false // 提交按钮加载中
                  if (!sub.success) return
                  this.close_drawer() // 关闭填写表单
                  this.$message.success('工单提交成功')
                  this.load_GD() // 重新获取最新的列表数据
                })
              })
          })
          .catch(() => {})
      })
    },
    // 加载列表数据
    load_GD() {
      const params = {
        userId: this.userInfo.userId
      }
      this.orderTableLoading = true
      this.table_jq.order = [
        {
          code: 'SY_CREATETIME',
          type: 'desc'
        }
      ]
      this.table_jq && (params.j_query = JSON.stringify(this.table_jq))
      !JSON.parse(params.j_query) && delete params.j_query
      getLoadWork(Object.assign(params, this.page_obj)).then((res) => {
        this.orderTableLoading = false
        // !this.userInfo.customerId && (this.VIP_CODE_show = true)
        // if (!res.success && res.message.includes('未绑定客户')) {
        //   this.VIP_CODE_show = true;
        //   return;
        // }
        // if (!res.success) return this.errorMessage(res.message)
        this.totaCount = res.totalCount // 当前的数据条数
        res.rows = this.load_GD_filter(res.rows)
        this.tableData = res.rows

        this.tableData.forEach(item => {
          item.isOldData = item.KHGD_SJJD ? new Date(item.KHGD_SJJD) > new Date(item.SY_CREATETIME) : true
        })
      })
    },
    // 前往绑定VIP客户码
    VIP_CODE_showGo() {
      this.$router.push({
        path: '/modifyInfo'
      })
    },
    load_GD_filter(rows) {
      const arr = rows
      arr.forEach((fil) => {
        const filterData = {
          '&lt;': '<',
          '&gt;': '>',
          '&quot;': '"',
          '&nbsp;': ' ',
          '&amp': '&'
        }
        fil.KHGD_WTMS && (fil.KHGD_WTMS = fil.KHGD_WTMS.replace(/&lt;/gi, '<'))
        fil.KHGD_WTMS && (fil.KHGD_WTMS = fil.KHGD_WTMS.replace(/&gt;/gi, '>'))
        fil.KHGD_WTMS &&
          (fil.KHGD_WTMS = fil.KHGD_WTMS.replace(/&quot;/gi, '"'))
        fil.KHGD_WTMS &&
          (fil.KHGD_WTMS = fil.KHGD_WTMS.replace(/&nbsp;/gi, 'a'))
        fil.KHGD_WTMS &&
          (fil.KHGD_WTMS = fil.KHGD_WTMS.replace(/(\&amp;)|(nbsp;)/gi, ' '))
        fil.KHGD_PTFK && (fil.KHGD_PTFK = fil.KHGD_PTFK.replace(/&lt;/gi, '<'))
        fil.KHGD_PTFK && (fil.KHGD_PTFK = fil.KHGD_PTFK.replace(/&gt;/gi, '>'))
        fil.KHGD_PTFK &&
          (fil.KHGD_PTFK = fil.KHGD_PTFK.replace(/&quot;/gi, '"'))
        fil.KHGD_PTFK &&
          (fil.KHGD_PTFK = fil.KHGD_PTFK.replace(/(\&amp;)|(nbsp;)/gi, ' '))
      })
      return arr
    },
    // 点击上传附件
    uploadFJ() {
      if (this.zd_codes.KHGD_WTFJ.length >= 5)
        return this.$message.error('最多只能上传五个附件')
      document.querySelector('.avatar-uploader-fj input').click()
    },
    // 根据后缀显示文件
    getFileSuffixIcon(file) {
      if (!file) return
      if (!file.suffix) {
        file.suffix = file.name.split('.')[1]
      }
      return fileSuffixIcon(file.suffix)
    },
    // 平台附件下载
    downloadPTMS(file) {
      const url = `/jepaas/document/down?fileKey=${file.id}`
      const tempLink = document.createElement('a')
      tempLink.style.display = 'none'
      // tempLink.download = file.name;
      tempLink.href = url
      tempLink.setAttribute('download', file.name)
      document.body.appendChild(tempLink)
      tempLink.click()
      document.body.removeChild(tempLink)
      // window.open(`/jepaas/document/down?fileKey=${file.id}`);
    },
    // 附件的上传成功
    uploadFJSuccess(res) {
      if (!res.success) return this.errorMessage(res.message)
      this.zd_codes.KHGD_WTFJ.push({
        name: res.obj[0].relName,
        path: res.obj[0].fileKey,
        id: res.obj[0].fileKey,
        suffix: res.obj[0].suffix
      })
      this.quillUpdateImg_fj = false
    },
    // 富文本图片上传成功
    uploadSuccess(res) {
      if (!res.success) return this.errorMessage(res.message)
      const { quill } = this.$refs.myQuillEditor
      const length = quill.getSelection().index
      quill.insertEmbed(
        length,
        'image',
        `/jepaas/document/preview?fileKey=${res.obj[0].fileKey}`
      )
      quill.setSelection(length + 1)
      this.quillUpdateImg = false
    },
    // 富文本禁止编辑
    onEditorFocus(event) {
      this.find_GD_status && event.enable(false)
      !this.find_GD_status && event.enable(true)
    },
    // 修改上传图片问题
    HandleCustomMatcher(node, Delta) {
      if (this.once === false) {
        // 文字、图片等，从别处复制而来，清除自带样式，转为纯文本
        const ops = []
        Delta.ops.forEach((op) => {
          if (op.insert && (typeof op.insert === 'string' || op.insert.image)) {
            ops.push({
              insert: op.insert
            })
          }
        })
        Delta.ops = ops
        return Delta
      }
      return Delta
    },
    onEditorFocus_KHGD_PTFK(event) {
      event.enable(false)
    },
    // 富文本图片上传失败
    uploadError() {
      this.$message.error('图片插入失败')
    },
    // 富文本图片上传前
    beforeAvatarUpload(file) {
      this.quillUpdateImg = true
      this.uploadData.files = file
      this.uploadData.uploadFieldName = 'files'
      this.uploadData.uploadableFields = 'files'
      this.uploadData.fileTypes = '*'
      this.uploadData.jeFileType = 'PROJECT'
    },
    upload_fjExceed(files, fileList) {
      this.$message.warning(
        `附件限制最多可上传 5 个文件，本次选择了 ${files.length} 个文件，共 ${
          files.length + fileList.length
        } 个文件`
      )
    },
    // 附件上传
    beforeAvatarUpload_fj(file) {
      this.quillUpdateImg_fj = true
      this.uploadData.files = file
      this.uploadData.uploadFieldName = 'files'
      this.uploadData.uploadableFields = 'files'
      this.uploadData.fileTypes = '*'
      this.uploadData.jeFileType = 'PROJECT'
    },
    // 关闭抽屉执行初始化
    close_init_drawer() {
      this.change_GD_data = {} // 当前点击的数据
      this.find_GD_status = false
      this.edit_GD_status = false
    },
    // 关闭抽屉执行方法
    handleClose(done) {
      if (this.sub_loading || this.sub_loading) {
        return
      }
      this.fileList = [] // 清空当前文件上传的目录
      this.close_init_drawer()
      done()
    },
    // 表格的选中事件
    handleSelectionChange(val) {
      this.tableSelection = val
    },
    // 根据编码获取状态颜色
    getColorsFilter(text) {
      return text ? colorsFilter(text): ''
    },
    copyDataCreateNewTask(row) {
      const params = row
      params.action = 'FZ'
      this.subTaskPort(params.JEPAAS_VIP_KHGD_ID, params).then((sub) => {
        if (!sub.success) return
        let arrData = this.load_GD_filter([sub.obj.values])
        arrData[0].action = 'FZ'
        this.handleEditClick(arrData[0])
        this.edit_GD_status = false
      })
    },
    subTaskFunc(row, enTxt, txt) {
      const params = row
      params.action = enTxt
      this.subTaskPort(params.JEPAAS_VIP_KHGD_ID, params).then((sub) => {
        if (!sub.success) return
        this.$message.success(`工单${txt}成功`)
        this.load_GD() // 重新获取最新的列表数据
      })
    },
    // 单独调用提交工单接口
    subTaskPort(id, params) {
      const projectId = params.JE_PRO_PRODUCT_ID
      const type = params.KHGD_GZTYLE_CODE
      const action = params.action

      return getSponsorProcess({ 
        userId: this.userInfo.userId,
        projectId,
        id,
        type,
        action,
      }).then(
        (res) => {
          if (!res.success) return this.errorMessage(res.message)
          return res
        }
      )
    },
    // 单独调用编辑方法
    updateTaskPort(params) {
      return getDoUpdate({ ...params }).then((res) => {
        if (!res.success) return this.errorMessage(res.message)
        return res
      })
    },
    // 单独调用保存的接口
    saveTaskPort(params) {
      return getDoSave({ ...params }).then((res) => {
        if (!res.success) return this.errorMessage(res.message)
        return res
      })
    },
    // 当前表单需要的数据格式
    paramsFilterSub() {
      let params = {
        userId: this.userInfo.userId,
        KHGD_GDLX_NAME: '一般工单',
        KHGD_GDLX_CODE: 'YBGD',
        KHGD_GZTYLE_CODE: 'WQD',
        KHGD_GZTYLE_NAME: '未启动',
      }
      // 将数据格式转化为接口所需要的数据形式
      Object.keys(this.zd_codes).forEach((key) => {
        if (
          this.zd_codes[key] instanceof Object &&
          !['KHGD_PTFKFJ', 'KHGD_WTFJ'].includes(key)
        ) {
          params[this.keys_map[key].code] = JSON.parse(
            this.zd_codes[key].value
          ).DICTIONARYITEM_ITEMCODE
          params[this.keys_map[key].name] = JSON.parse(
            this.zd_codes[key].value
          ).DICTIONARYITEM_ITEMNAME
        } else {
          params[key] = this.zd_codes[key] || ''
        }
      })
      // 项目的下拉选中e
      if (this.xm_select.value) {
        const oob = JSON.parse(this.xm_select.value)
        // eslint-disable-next-line no-const-assign
        params = Object.assign(params, oob)
      }
      // 项目计划的下拉选中
      if (this.xmjh_select.value) {
        const oob2 = JSON.parse(this.xmjh_select.value)
        // eslint-disable-next-line no-const-assign
        params = Object.assign(params, oob2)
      }
      // 如果是编辑状态
      // if(this.edit_GD_status){

      // }
      params.JE_PRO_CPMK_ID = params.JE_XMGL_XMJH_ID
      params.KHGD_XMMK = params.XMJH_MC
      params.KHGD_GZTYLE_CODE = params.KHGD_GZTYLE_CODE === '' ? 'WQD' : params.KHGD_GZTYLE_CODE
      params.KHGD_WTFJ = JSON.stringify(this.zd_codes.KHGD_WTFJ)
      return params
    },
    // 关闭填写工单的方法
    close_drawer() {
      this.change_GD_data = {} // 当前点击的数据
      this.edit_GD_status = false
      this.find_GD_status = false // 当前是查看不可更改
      this.dialog = false
    },
    // 判读必填属性是否成功
    isSubData() {
      let status = true
      Object.keys(this.zd_codes).forEach((key) => {
        if (
          this.zd_codes[key] instanceof Object &&
          !['KHGD_PTFKFJ', 'KHGD_WTFJ'].includes(key)
        ) {
          if (!this.zd_codes[key].value && this.zd_codes[key] && status) {
            status = false
            this.$message.error(`${this.zd_codes[key].name}为必填项!`)
          }
        } else {
          const obj = {
            KHGD_YCDSXX: '远程调试',
            KHGD_WTMS: '问题描述',
            KHGD_WTBT: '问题标题'
          }
          !this.zd_codes[key] &&
            obj[key] &&
            status &&
            this.$message.error(`${obj[key]}为必填项!`) &&
            (status = false)
        }
        this.xm_select.value == '' &&
          status &&
          this.$message.error('项目为必填项!') &&
          (status = false)

        this.xmjh_select.value == '' &&
          status &&
          this.$message.error('项目计划为必填项!') &&
          (status = false)
      })
      return status
    },
    // 保存工单
    cancelForm() {
      if (!this.isSubData()) return // 判断必填项是否填写
      const params = this.paramsFilterSub()
      // 校验当前用户的VIP状态
      this.vipCheckVipService().then((vip) => {
        // 如果Vip校验不通过
        if (!vip.success) {
          return this.errorMessage(vip.message)
        }
        this.edit_GD_status &&
          this.updateTaskPort(params).then((res) => {
            if (!res) return
            this.$message.success('工单修改完毕')
            this.load_GD() // 重新获取最新的列表数据
            // this.close_drawer();
          })
        // 保存工单接口调用
        !this.edit_GD_status &&
          this.saveTaskPort(params).then((res) => {
            if (!res) return
            this.$message.success('工单填写完毕')
            this.load_GD() // 重新获取最新的列表数据
            this.zd_codes = Object.assign(this.zd_codes, res.obj)
            this.zd_codes.KHGD_WTFJ = this.zd_codes.KHGD_WTFJ ? JSON.parse(this.zd_codes.KHGD_WTFJ): []
            this.edit_GD_status = true
          })
      })
    },
    // 初始进入页面获取字典信息
    get_select() {
      getDictionary({ codes: 'JEPAAS_DJZT', userId: this.userInfo.userId }).then(
        (res) => {
          res.obj.JEPAAS_DJZT = res.obj.JEPAAS_DJZT.filter(
            (fil) => fil.DICTIONARYITEM_ITEMCODE != 'YPJ'
          )
          const jxz = {
            DICTIONARYITEM_ITEMCODE: [],
            DICTIONARYITEM_ITEMNAME: '进行中'
          }
          res.obj.JEPAAS_DJZT.forEach((select) => {
            if (['WQD', 'YGB'].indexOf(select.DICTIONARYITEM_ITEMCODE) === -1 ) {
              jxz.DICTIONARYITEM_ITEMCODE.push(select.DICTIONARYITEM_ITEMCODE)
            }
          })
          jxz.DICTIONARYITEM_ITEMCODE = jxz.DICTIONARYITEM_ITEMCODE.join(',')
          this.taskSelect.push(jxz)
          this.taskSelect = [...this.taskSelect, ...res.obj.JEPAAS_DJZT]
          this.taskOnSelect(jxz)
        }
      )
    },
    // 点击添加工单按钮 --- 根据字典接口获取对应的字典
    async addGD_btn() {
      // 初始化
      this.zd_codes = {
        KHGD_GDBH: '<系统自动生成>',
        KHGD_GZTYLE_CODE: '', // 数据的状态码
        VIP_PRODUCT_TYOE: {
          value: '', // 当前下拉菜单选中的值
          codes: [], // 当前包含的所有下拉菜单
          type: 'select',
          name: '平台产品'
        }, // 平台产品字典
        JE_PRO_OS: {
          value: '', // 当前下拉菜单选中的值
          codes: [], // 当前包含的所有下拉菜单
          type: 'select',
          name: '操作系统'
        }, // 操作系统字典
        VIP_DB_TYPE: {
          value: '', // 当前下拉菜单选中的值
          codes: [], // 当前包含的所有下拉菜单
          type: 'select',
          name: '数据库'
        }, // 数据库字典
        JE_PRO_BORWSER: {
          value: '', // 当前下拉菜单选中的值
          codes: [], // 当前包含的所有下拉菜单
          name: '浏览器'
        }, // 浏览器字典
        JE_PM_QZLX: {
          value: '', // 当前下拉菜单选中的值
          codes: [], // 当前包含的所有下拉菜单
          name: '问题类型'
        }, // 问题类型字典
        VIP_PRODUCT_TYOE_TREE: {
          value: '', // 当前下拉菜单选中的值
          allCodes: [], // 当前所有的字典信息
          codes: [], // 当前包含的所有下拉菜单
          name: '产品版本'
        }, // 产品版本
        KHGD_TCR: this.userInfo.userName || '', // 提出人
        KHGD_YCDSXX: '', // 远程调试信息
        KHGD_WTBT: '', // 问题标题
        KHGD_WTMS: '', // 问题描述
        KHGD_PTFK: '', // 平台反馈
        KHGD_BHXX: '', // 驳回原因
        KHGD_PTFKFJ: [], // 平台反馈附件
        KHGD_WTFJ: [] // 问题附件
      }
      this.isShowTaskInfo = false
      this.xm_select.value = '' // 项目的默认值为空
      this.xmjh_select.value = '' // 项目计划的默认值为空
      this.xmjh_select.valueArr = [] // 项目计划的默认值为空
      const params = []
      // 遍历字典的key键
      Object.keys(this.zd_codes).forEach((key) => {
        if (this.zd_codes[key] instanceof Object) params.push(key) // 将需要获取的字典字段存入数组
      })
      const params_string = params.join(',')

      // 获取项目名称的字典下拉
      let projectData = await getTaskProject({ userId: this.userInfo.userId })
      if (!projectData.success) {
        this.task_verify_dialog.isShow = true
        this.task_verify_dialog.txt = projectData.message
        return false
      }

      this.xm_select.valueArr = []
      projectData.obj.forEach((xm) => {
        this.xm_select.valueArr.push({
          KHGD_XM: xm.XMLXGL_XMMC || '',
          KHGD_XMH: xm.XMLXGL_XMH || '',
          JE_PRO_PRODUCT_ID: xm.PM_XMGL_XMLXGL_ID || '',
          KHGD_XMMK: xm.KHXM_XMMK || '',
          JE_PRO_CPMK_ID: xm.JE_PRO_CPMK_ID || '',
          KHGD_XMLCB_NAME: xm.KHXM_XMLCB_NAME || '',
          KHGD_XMLCB_CODE: xm.KHXM_XMLCB_CODE || ''
        })
      })

      if (this.xm_select.valueArr.length === 1 && !this.edit_GD_status) {
        this.xm_select.value = JSON.stringify(this.xm_select.valueArr[0])

        const projectId = this.xm_select.valueArr[0].JE_PRO_PRODUCT_ID

        const res = await getcheckVipService({ projectId })
        if (!res.success) {
          this.task_verify_dialog.isShow = true
          this.task_verify_dialog.txt = res.message
          return false
        }

        this.isShowTaskInfo = true
        this.taskInfo = res.obj.values

        const res3 = await getPlan({projectId})
        if (!res3.success) {
          this.task_verify_dialog.isShow = true
          this.task_verify_dialog.txt = res3.message
          return false
        } 

        this.xmjh_select.valueArr = res3.obj
        if (this.xmjh_select.valueArr.length === 1 && !this.edit_GD_status) {
          this.xmjh_select.value =  JSON.stringify(this.xmjh_select.valueArr[0])
        }
      }

      return getDictionary({
        codes: params_string,
        userId: this.userInfo.userId
      }).then(async (res) => {
        if (!res.success) return this.errorMessage(res.message)
        // 获取到接口中的字典信息循环加到对应的列表字段信息
        Object.keys(res.obj).forEach((key) => {
          if (key == 'VIP_PRODUCT_TYOE') {
            this.zd_codes.VIP_PRODUCT_TYOE = {
              value: JSON.stringify(res.obj.VIP_PRODUCT_TYOE[0]), // 当前下拉菜单选中的值
              codes: res.obj.VIP_PRODUCT_TYOE, // 当前包含的所有下拉菜单
              type: 'select',
              name: '平台产品'
            }
          } else {
            key == 'VIP_PRODUCT_TYOE_TREE' &&
              (this.zd_codes.VIP_PRODUCT_TYOE_TREE.allCodes = JSON.parse(
                JSON.stringify(res.obj.VIP_PRODUCT_TYOE_TREE)
              ))
            this.zd_codes[key].codes = res.obj[key] // 字段对应的所有字段
          }
        })

        this.zd_codes.JE_PRO_BORWSER.value = this.zd_codes.JE_PRO_BORWSER.codes.length > 0 ? JSON.stringify(this.zd_codes.JE_PRO_BORWSER.codes[0]) : ''
        this.zd_codes.VIP_DB_TYPE.value = this.zd_codes.VIP_DB_TYPE.codes.length > 0 ? JSON.stringify(this.zd_codes.VIP_DB_TYPE.codes[0]) : ''
        this.zd_codes.JE_PRO_OS.value = this.zd_codes.JE_PRO_OS.codes.length > 0 ? JSON.stringify(this.zd_codes.JE_PRO_OS.codes[0]) : ''
        this.zd_codes.JE_PM_QZLX.value = this.zd_codes.JE_PM_QZLX.codes.length > 0 ? JSON.stringify(this.zd_codes.JE_PM_QZLX.codes[0]) : ''

        this.filterDicTree()
        this.dialog = true // 显示填写表单的滑出框
      })
    },
    async changeXM(val) {
      const projectId = JSON.parse(val).JE_PRO_PRODUCT_ID

      const res = await getcheckVipService({ projectId })
      if (!res.success) {
        this.isShowTaskInfo = false
        this.xmjh_select.value = ''
        this.xmjh_select.valueArr = []
        this.task_verify_dialog.isShow = true
        this.task_verify_dialog.txt = res.message
        return false
      } 

      this.isShowTaskInfo = true
      this.taskInfo = res.obj.values

      const res3 = await getPlan({projectId})
      if (!res3.success) {
        this.task_verify_dialog.isShow = true
        this.task_verify_dialog.txt = res3.message
        return false
      } 

      this.xmjh_select.value = ''
      this.xmjh_select.valueArr = res3.obj
      if (this.xmjh_select.valueArr.length === 1) {
        this.xmjh_select.value = JSON.stringify(this.xmjh_select.valueArr[0]) 
      }
    },
    // 单独抽离方法判断tree
    filterDicTree() {
      let ptCode = ''
      if (this.zd_codes.VIP_PRODUCT_TYOE.value) {
        ptCode = JSON.parse(
          this.zd_codes.VIP_PRODUCT_TYOE.value
        ).DICTIONARYITEM_ITEMCODE // 当前选中的平台产品
      }
      const treeData = this.zd_codes.VIP_PRODUCT_TYOE_TREE.allCodes.filter(
        (res) => res.DICTIONARYITEM_ITEMCODE == ptCode
      ) // 当前的产品信息
      this.zd_codes.VIP_PRODUCT_TYOE_TREE.codes =
        this.zd_codes.VIP_PRODUCT_TYOE_TREE.allCodes.filter(
          (res) => {
            if (treeData.length > 0) {
              return res.SY_PARENT == treeData[0].JE_CORE_DICTIONARYITEM_ID
            } else {
              return false
            }
          }
        )
      this.zd_codes.VIP_PRODUCT_TYOE_TREE.value = JSON.stringify(
        this.zd_codes.VIP_PRODUCT_TYOE_TREE.codes[0]
      )
    },
    // 按钮送达和退回
    handleBtnClick(data, type) {
      if (type == 'GB') {
        this.$alert('您确定关闭工单吗？', '退回', {
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        })
          .then(() => {
            data.KHGD_GZTYLE_CODE = 'YGB'
            data.KHGD_GZTYLE_NAME = '已关闭'
            data.userId = this.userInfo.userId
            this.updateTaskPort(data).then((res) => {
              if (!res) return
              this.$message.success('工单已关闭')
              this.load_GD() // 重新获取最新的列表数据
            })
          })
          .catch(() => {})
      }
    },
    // 分页点击
    blackPageHandleChange(val) {
      this.page_obj.page = val // 当前页数
      this.page_obj.start = (val - 1) * 10 // 当前开始条数
      this.load_GD() // 加载列表数据
    },
    // 关键词数据框数据
    searchInput() {
      this.get_upload_jq() // 获取当前最新的j_query
    },
    // 获取当前最新的j_query
    get_upload_jq() {
      // 因为每次拼接jq的条件都不一样，如果输入框已经有内容，则拼接两条数据的查询条件
      let params = {}
      // 如果选中的是进行中的数据
      if (this.taskSelectText.DICTIONARYITEM_ITEMNAME == '进行中') {
        const codes = this.taskSelectText.DICTIONARYITEM_ITEMCODE.split(',')
        params.group = [
          {
            type: 'and',
            value: [
              {
                code: 'KHGD_GZTYLE_CODE',
                type: 'in',
                value: codes
              }
            ]
          }
        ]
        if (this.GD_id) {
          const arrs = ['KHGD_GDBH', 'KHGD_WTBT']
          arrs.forEach((key) => {
            params.group[0].value.push({
              code: key,
              type: 'like',
              value: this.GD_id
            })
          })
        }
        // 如果工单类型也存在内容
        if (this.taskTypeSelectText.code != 'all') {
          params.group.push({
            type: 'and',
            value: [
              {
                code: 'KHGD_GDLX_CODE',
                type: '=',
                value: this.taskTypeSelectText.code
              }
            ]
          })
        }
        this.table_jq = params
        this.load_GD() // 每次更新jq就重新获取最新
        return
      }
      // 如果只存在快速查询
      if (this.taskSelectText.DICTIONARYITEM_ITEMCODE != 'all' && !this.GD_id) {
        params.group = [
          {
            type: 'and',
            value: [
              {
                code: 'KHGD_GZTYLE_CODE',
                type: 'in',
                value: [this.taskSelectText.DICTIONARYITEM_ITEMCODE]
              }
            ]
          }
        ]
      }
      // 如果快速下拉根据状态查询不存在数据
      if (this.taskSelectText.DICTIONARYITEM_ITEMCODE == 'all' && this.GD_id) {
        params = {
          quick: [
            {
              type: 'and',
              value: [
                {
                  code: 'KHGD_GDBH',
                  type: 'like',
                  value: this.GD_id,
                  cn: 'or'
                },
                {
                  code: 'KHGD_WTBT',
                  type: 'like',
                  value: this.GD_id,
                  cn: 'or'
                }
              ]
            }
          ]
        }
      }
      // 如果已经有模糊查询，则拼接两条查询数据
      if (this.taskSelectText.DICTIONARYITEM_ITEMCODE != 'all' && this.GD_id) {
        const arrs = ['KHGD_GDBH', 'KHGD_WTBT']
        params.group = [
          {
            type: 'and',
            value: [
              {
                code: 'KHGD_GZTYLE_CODE',
                type: 'in',
                value: [this.taskSelectText.DICTIONARYITEM_ITEMCODE]
              }
            ]
          }
        ]
        arrs.forEach((key) => {
          params.group[0].value.push({
            code: key,
            type: 'like',
            value: this.GD_id
          })
        })
      }
      // 如果工单类型也存在内容
      if (this.taskTypeSelectText.code != 'all') {
        if (!params.group) {
          params.group = [
            {
              type: 'and',
              value: [
                {
                  code: 'KHGD_GDLX_CODE',
                  type: '=',
                  value: this.taskTypeSelectText.code
                }
              ]
            }
          ]
        } else {
          params.group.push({
            type: 'and',
            value: [
              {
                code: 'KHGD_GDLX_CODE',
                type: '=',
                value: this.taskTypeSelectText.code
              }
            ]
          })
        }
      }
      this.table_jq = params
      this.load_GD() // 每次更新jq就重新获取最新
    },
    checkSelectable(row) {
      const showCheck = ['WQD']
      return showCheck.includes(row.KHGD_GZTYLE_CODE)
      // return row.date == '2016-05-03'
    },
    // 筛选订单状态的问题
    taskOnSelect(val) {
      this.taskSelectText = val
      this.get_upload_jq()
    },
    taskTypeSelectFunc(val) {
      this.taskTypeSelectText.code = val
      this.get_upload_jq()
    },
    // 格式化编辑
    editFilter(key1, obj, key2) {
      const obj_arr = this.zd_codes[key1].codes.filter(
        (res) => res.DICTIONARYITEM_ITEMCODE == obj[key2]
      )
      return JSON.stringify(obj_arr[0])
    },
    // 表单的查看操作
    find_GD(row) {
      if (row.KHGD_GZTYLE_CODE == 'WQD') {
        this.handleEditClick(row)
        return
      }
      // // 如果是驳回状态 单独处理
      if (row.KHGD_GZTYLE_CODE == 'YBH' && !row.isOldData) {
        this.edit_GD_status = true
        this.find_GD_status = false // 当前是查看不可更改
        this.change_GD_data = Object.assign(this.zd_codes, row) // 当前点击的数据
        this.handleEditClick(row)
        return
      }
      this.change_GD_data = Object.assign(this.zd_codes, row) // 当前点击的数据
      this.handleEditClick(row)
      this.edit_GD_status = false
      this.find_GD_status = true // 当前是查看不可更改
    },
    // 表编辑点击操作
    handleEditClick(row) {
      this.edit_GD_Loading = true // 当前是编辑状态 --- 数据返回前
      this.edit_GD_status = true // 当前是编辑状态
      this.addGD_btn().then(async () => {
        const zd_obj = {
          VIP_PRODUCT_TYOE: 'KHGD_PTCP_CODE',
          JE_PRO_OS: 'KHGD_CZXT_CODE',
          VIP_DB_TYPE: 'KHGD_SJK_CODE',
          JE_PRO_BORWSER: 'KHGD_LLQ_CODE',
          JE_PM_QZLX: 'KHGD_WTLX_CODE',
          VIP_PRODUCT_TYOE_TREE: 'KHGD_CPBB'
        } // 对照着接口的数据, 下拉菜单和输入框的赋值
        Object.keys(this.zd_codes).forEach((key) => {
          if (
            this.zd_codes[key] instanceof Object &&
            !['KHGD_PTFKFJ', 'KHGD_WTFJ'].includes(key) != ''
          ) {
            this.zd_codes[key].value = this.editFilter(key, row, zd_obj[key])
            key == 'VIP_PRODUCT_TYOE' && this.filterDicTree()
          } else if (!['KHGD_PTFKFJ', 'KHGD_WTFJ'].includes(key)) {
            this.zd_codes[key] = row[key]
          } else {
            this.zd_codes.KHGD_WTFJ =
              (row.KHGD_WTFJ && JSON.parse(row.KHGD_WTFJ)) || ''
            this.zd_codes.KHGD_PTFKFJ =
              (row.KHGD_PTFKFJ && JSON.parse(row.KHGD_PTFKFJ)) || ''
          }
          // 平台给的问题附件如果有图片则进行添加http处理
          if (
            key == 'KHGD_PTFK' &&
            this.zd_codes[key] &&
            this.zd_codes[key].includes('img')
          ) {
            this.zd_codes[key] = this.zd_codes[key].replace(
              /<img [^>]*src=['"]([^'"]+)[^>]*>/gi,
              (ma, ca) => {
                let imgStr = ''
                ;(ca.includes('http') && (imgStr = ca)) ||
                  (imgStr = `https://suanbanyun.com${ca}`)
                return `<img src="${imgStr}" title="image.png" alt="image.png">`
              }
            )
          }
        })
        // 项目的下拉回显
        this.xm_select.valueArr.forEach((xm) => {
          if (row.JE_PRO_PRODUCT_ID == xm.JE_PRO_PRODUCT_ID) {
            this.xm_select.value = JSON.stringify(xm)
          }
        })

        const res = await getcheckVipService({ projectId: row.JE_PRO_PRODUCT_ID })
        if (res.success) {
          this.isShowTaskInfo = true
          this.taskInfo = res.obj.values
        }

        const res3 = await getPlan({projectId: row.JE_PRO_PRODUCT_ID})
        this.xmjh_select.valueArr = res3.obj ? res3.obj : []

        // 项目计划的下拉回显
        this.xmjh_select.valueArr.forEach((xm) => {
          if (row.JE_PRO_CPMK_ID == xm.JE_XMGL_XMJH_ID) {
            this.xmjh_select.value = JSON.stringify(xm)
          }
        })

        // 编辑可能需要的参数
        this.zd_codes.JEPAAS_VIP_KHGL_ID = row.JEPAAS_VIP_KHGL_ID || ''
        this.zd_codes.JEPAAS_VIP_KHGD_ID = row.JEPAAS_VIP_KHGD_ID || ''

        if (row.action !== 'FZ'){
           this.zd_codes.JEPAAS_VIP_KHGD_ID = row.JEPAAS_VIP_KHGD_ID || ''
        } else {
          delete this.zd_codes.JEPAAS_VIP_KHGD_ID 
        }

        this.zd_codes.JE_PRO_PRODUCT_ID = row.JE_PRO_PRODUCT_ID || ''
        this.zd_codes.JE_XMGL_XMJH_ID = row.JE_PRO_CPMK_ID || ''
        this.zd_codes.KHGD_XMLCB_CODE = row.KHGD_XMLCB_CODE || ''
        this.edit_GD_Loading = false // 当前是编辑状态 --- 数据返回前
      })
    },
    // 时间切割
    spliceTime(time) {
      return time.substring(0, 16)
    },
    // 已用工时格式化
    filterTime(min) {
      if (min == 0) return '0分钟'
      let minTime = ''
      const h = Math.floor(min / 60)
      min -= h * 60
      if (min == 0) {
        minTime = h ? `${h}小时00分钟` : ''
      } else {
        if (min < 10) {
          min = `0${min}`
        }
        minTime = (h ? `${h}小时` : '') + (min ? `${min}分钟` : '')
      }
      return minTime
    },
    // 通用的报错提示
    errorMessage(msg) {
      this.$message.error(`${msg}`)
    },
    // 查看VIP服务
    vipCheck() {
      this.vipCheckVipService().then((vip) => {
        if (!vip.success) return this.errorMessage(vip.message)
        this.vipCheck_obj.vipCheckDialog = true
        this.vipCheck_obj.time = {
          start: vip.obj.values.VIPTIME_KSSJ,
          end: vip.obj.values.VIPTIME_JSSJ
        }
        this.vipCheck_obj.VIPTIME_YYGDSL = vip.obj.values.VIPTIME_YYGDSL // 已用工单数量
        this.vipCheck_obj.VIPTIME_GDSL = vip.obj.values.VIPTIME_GDSL // 工单总数量
        this.vipCheck_obj.SY_STATUS =
          vip.obj.values.VIPTIME_GDSL - vip.obj.values.VIPTIME_YYGDSL // 剩余工单数量
      })
    },
    // vip的记录获取接口
    getTaskViptime() {
      getViptime({ customerId: this.userInfo.customerId }).then((res) => {
        if (!res.success) return this.errorMessage(res.message)
        if (res.obj.length == 0) {
          this.vipCheck_obj.vipLength_0 = true
        } else {
          this.vipCheck_obj.vipLength_0 = false
        }
        const oldArr = []
        const newArr = []
        const future = []
        res.obj.forEach((vip) => {
          const oldDayEndTime = new Date(vip.VIPTIME_JSSJ).getTime() // 接口的日期
          const oldDayStartTime = new Date(vip.VIPTIME_KSSJ).getTime() // 接口开始的日期
          const newDayTime = new Date().getTime() // 今天的日期
          // 如果vip的结束时间 小于今天的日期就归类到过期
          if (newDayTime > oldDayEndTime) {
            oldArr.push(vip)
          } else if (
            oldDayStartTime < newDayTime &&
            oldDayEndTime > newDayTime
          ) {
            newArr.push(vip)
          }
          // 找出当前正在执行的月份VIP
          if (oldDayStartTime < newDayTime && oldDayEndTime > newDayTime) {
            this.vipCheck_obj.time = {
              start: vip.VIPTIME_KSSJ,
              end: vip.VIPTIME_JSSJ
            }
            this.vipCheck_obj.VIPTIME_YYGDSL = vip.VIPTIME_YYGDSL // 已用工单数量
            this.vipCheck_obj.VIPTIME_GDSL = vip.VIPTIME_GDSL // 工单总数量
            this.vipCheck_obj.SY_STATUS = vip.VIPTIME_GDSL - vip.VIPTIME_YYGDSL // 剩余工单数量
          }
        })
        res.obj.forEach((vip) => {
          const oldDayStartTime = new Date(vip.VIPTIME_KSSJ).getTime() // 接口开始的日期
          const tt = new Date(this.vipCheck_obj.time.end).getTime()
          if (oldDayStartTime > tt) {
            future.push(vip)
          }
        })
        this.vipCheck_obj.vip_arr = { oldArr, newArr, future }
        this.vipCheck_obj.vipCheckDialog = true
      })
    },
    // 校验当前用户的VIP状态
    vipCheckVipService() {
      const projectId = JSON.parse(this.xm_select.value).JE_PRO_PRODUCT_ID
      return getcheckVipService({ projectId }).then(
        (res) => res
      )
    },
    // 列表点击的操作
    handleRemoveClick(row) {
      let ids = ''
      if (Array.isArray(row)) {
        ids = row.map((res) => res.JEPAAS_VIP_KHGD_ID)
        ids = ids.join(',')
      } else {
        ids = row.JEPAAS_VIP_KHGD_ID
      }
      this.$alert('您确认要删除该工单吗？', '删除确认', {
        confirmButtonText: '确定',
        callback: (action) => {
          if (action != 'confirm') return
          getDoRemove({ userId: this.userInfo.userId, ids }).then((res) => {
            if (!res.success) return this.errorMessage(res.message)
            this.$message.success(res.obj)
            this.load_GD()
          })
        }
      })
    }
  }
}
</script>
<style lang="less">
@import './style/index';
</style>
<style lang="less" scoped>
@import '../../../style/shop/util';
.Jepaas-myTask {
  min-height: 100vh;
  position: relative;
  width: 100%;
  .displayHeightCenter {
    display: flex;
    align-items: center;
  }
  .upload_fuJi {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    text-align: center;
    overflow: hidden;
    width: 100%;
    &:hover {
      border-color: #409eff;
    }
    i {
      font-size: 20px;
      color: #8c939d;
      width: 100px;
      height: 100px;
      line-height: 100px;
    }
  }
  .FJSC_class {
    width: 100%;
    // border:1px solid #CCCCCC;
    // padding: 10px;
  }
  .page_box {
    z-index: 2000 !important;
  }
  .text__highlight {
    color: red !important;
    background: none !important;
  }
  .alreadyTable {
    position: relative;
    min-height: 600px;
    box-sizing: border-box;
    padding-bottom: 100px;
  }
  .el-backtop {
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
    z-index: 10;
    border-radius: 4px;
    width: 52px;
    height: 52px;
    right: 2% !important;
    bottom: 43px !important;
    font-size: 24px;
    color: #ff3041;
    &:hover {
      background: #ff3041;
      > i {
        color: #ffffff;
      }
    }
  }
  .box_top {
    width: 100%;
    height: 80px;
    background: linear-gradient(
      270deg,
      rgba(252, 103, 81, 1) 0%,
      rgba(246, 71, 77, 1) 100%
    );
  }
  .je-detail-content {
    margin: 0 auto;
    padding: 20px 0;
    width: 1250px;
    overflow: hidden;
    .elemnt-input-YGDD {
      .el-input__inner {
        border: none;
        border-radius: 20px;
      }
    }
    .title {
      height: 42px;
      font-size: 30px;
      font-weight: 600;
      color: rgba(63, 63, 63, 1);
      line-height: 42px;
    }
    .question {
      height: 70px;
      background: rgba(255, 242, 240, 1);
      margin-top: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 26px;
      font-weight: 500;
      .el-button {
        font-size: 26px;
        margin-left: 10px;
      }
    }
    .explains {
      margin-top: 30px;
      .el-tabs__item {
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 20px;
        font-weight: 400;
        color: rgba(63, 63, 63, 1);
        height: initial;
        line-height: normal;
      }
      .el-tabs__header {
        margin-bottom: 30px;
      }
      .el-tabs__active-bar {
        background-color: #f84e4e;
      }
    }
  }
}

.taskTop {
  display: flex;
  align-items: center;
  width: 60%;
  height: 50px;
  margin: 0 auto;
  padding: 0 30px;
  background: #F5E3E3;
  font-size: 16px;
  .iconWrap {
    display: inline-block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background: #fff;
    border-radius: 50%;
    .jeicon {
      color: #EE5656;
    }
  }
  .item {
    color: #de615c;
    margin: 0 0 0 40px;
  }
  .item:first-child {
    margin: 0 80px 0 0;
  }
  .number {
    font-weight: bold;
  }
}
</style>
