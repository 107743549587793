/*
 * @Descripttion:
 * @Author: 张明尧
 * @Date: 2020-12-01 14:35:55
 * @LastEditTime: 2021-02-23 09:40:20
 */
// 根据尾缀来确定icon
export function fileSuffixIcon(suffix) {
  //  先将尾缀统一变成小写
  const unKnow = {
    icon: 'jeicon-unknownfile',
    color: '#A9ABA9',
  };
  const allIcons = [
    {
      icon: 'jeicon-square-flash',
      suffixs: ['swf', 'rm', 'mov', 'swf', 'fla', 'flash'],
      color: '#46DDA2',
    }, // flash 的文件
    {
      icon: 'jeicon-jepg',
      suffixs: ['jpeg'],
      color: '#A3DB73',
      isImg: true, // 是否是图片
    }, // jepg 的文件
    {
      icon: 'jeicon-js',
      suffixs: ['js', 'json', 'script', 'javascript'],
      color: '#F4CE4B',
    }, // js 文件
    {
      icon: 'jeicon-ie',
      suffixs: ['ie', 'IE'],
      color: '#F8A646',
    }, // ie 的文件
    {
      icon: 'jeicon-svg',
      suffixs: ['svg'],
      isImg: true, // 是否是图片
      color: 'rgb(255, 119, 68)',
    }, // svg 的文件
    {
      icon: 'jeicon-square-gif',
      suffixs: ['gif'],
      isImg: true, // 是否是图片
      color: '#A3DB73',
    }, // gif 的文件
    {
      icon: 'jeicon-audio',
      suffixs: ['mp4', 'mp3'],
      color: '#F65A59',
    }, // 音频文件
    {
      icon: 'jeicon-mp3',
      suffixs: ['mp3', 'wma', 'wav', 'ape', 'mid'],
      color: '#F65A59',
    }, // mp3文件
    {
      icon: 'jeicon-exe',
      suffixs: ['exe'],
      color: '#4BB8F3',
    }, // exe文件
    {
      icon: 'jeicon-rar',
      suffixs: ['rar'],
      color: '#FBAF32',
    }, // rar文件
    {
      icon: 'jeicon-psd',
      suffixs: ['psd'],
      color: '#4BB8F3',
    }, // psd文件
    {
      icon: 'jeicon-bmp',
      suffixs: ['bmp'],
      isImg: true, // 是否是图片
      color: '#D088E1',
    }, // bmp文件
    {
      icon: 'jeicon-png',
      suffixs: ['png'],
      isImg: true, // 是否是图片
      color: '#4BB8F3',
    }, // png文件
    {
      icon: 'jeicon-jpg',
      suffixs: ['jpg'],
      isImg: true, // 是否是图片
      color: 'rgb(255, 119, 68)',
    }, // jpg文件
    {
      icon: 'jeicon-html',
      suffixs: ['html'],
      color: '#6DCC51',
    }, // html文件
    {
      icon: 'jeicon-dwf',
      suffixs: ['dwf'],
      color: '#6DCC51',
    }, // dwf文件
    {
      icon: 'jeicon-zip',
      suffixs: ['zip', '7-zip', '7z', 'winzip'],
      color: '#FBAF32',
    }, // zip文件
    {
      icon: 'jeicon-pdf',
      suffixs: ['pdf'],
      color: '#E52C0E',
    }, // pdf文件
    {
      icon: 'jeicon-ppt',
      suffixs: ['pptx', 'pptm', 'ppt', 'potx', 'potm', 'pot', 'ppsx', 'ppsm', 'xml'],
      color: '#F34E19',
    }, // ppt文件
    {
      icon: 'jeicon-txt',
      suffixs: ['txt'],
      color: '#2BA244',
    }, // ppt文件
    {
      icon: 'jeicon-excel',
      suffixs: ['xls', 'xlsx', 'xlsm'],
      color: '#00843E',
    }, // ppt文件
    {
      icon: 'jeicon-word',
      suffixs: ['doc', 'docx', 'dot', 'dotx', 'docm'],
      color: '#0057AA',
    }, // ppt文件
  ];
  if (!suffix) {
    return {
      icon: 'jeicon-folders',
      color: '#F7C12D',
    };
  }
  const lowSuffix = suffix.toLowerCase();
  let suffixObj = [];
  suffixObj = allIcons.filter((suff) => {
    if (suff.suffixs.includes(lowSuffix)) {
      return suff;
    }
  });
  suffixObj.length == 0 && (suffixObj.push(unKnow));
  return suffixObj[0];
}

// 根据状态码返回颜色判断
export function colorsFilter(text) {
  const colors = {
    YGB: {
      style: 'color: #409EFF',
    },
    YFP: {
      style: 'color: #EF4D4D',
    },
    YSZ: {
      style: 'color: #B56210',
    },
    WQD: {
      style: 'color: #8D98AB',
    },
    YTJ: {
      style: 'color: #288C4E',
    },
    YBH: {
      style: 'color: #EF4D4D',
    },
    YSL: {
      style: 'color: #FF6F45',
    },
    YPJ: {
      style: 'color: #F19B2C',
    },
    DYS: {
      style: 'color: #B56210',
    },
    CLZ: {
      style: 'color: #F19B2C',
    }
  };
  return colors[text].style;
}
